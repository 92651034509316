export const progressData = [
  {
    "year": 2024,
    "wrapper": [
      {
        "text": "金天国际核心制造企业烟台金蕊获评“用户满意五星企业”殊荣。"
      },
      {
        "text": "应邀出席2023宿迁人民警察节活动。"
      },
      {
        "text": "荣获“宿迁慈善奖—最具爱心慈善捐赠企业”荣誉称号。"
      },
      {
        "text": "出席中国健康促进基金会第四届理事会第四次会议。"
      },
      {
        "text": "再次荣任国家高端智库理事。"
      },
      {
        "text": "金天国际天山雪莲提取技术及应用专利成功入选2024年烟台市企业专利导航项目。"
      }
    ]
  },
  {
    "year": 2023,
    "wrapper": [
      {
        "text": "分别被央视以中国自立自强最高研发水平高科技项目代表和“一带一路”科技出口创新代表两度荣登《新闻联播》。"
      },
      {
        "text": "再度受邀出席2023年博鳌亚洲论坛年会。"
      },
      {
        "text": "受邀荣耀出席中国-中东欧国家联合商会第七次会议。"
      },
      {
        "text": "金天国际天山雪莲基地开出十二朵雪莲花，打破了天山雪莲一株开花数量的历史记录。"
      },
      {
        "text": "荣耀参展2023年第61届上海国际美博会。"
      },
      {
        "text": "荣获2023CIBE上海国际美博会颜值经济匠心品牌殊荣。"
      },
      {
        "text": "作为行业领军企业受邀参加首届链博会推进签约仪式。"
      },
      {
        "text": "雪莲养护贴亮相2023烟台绿色低碳高质量发展成果展。"
      },
      {
        "text": "受邀出席2023东盟峰会系列活动。"
      },
      {
        "text": "受邀出席中国健康促进基金会第四届理事会第三次会议。"
      },
      {
        "text": "受邀出席第三届“一带一路”国际合作高峰论坛。"
      },
      {
        "text": "金天国际雪莲养护贴产品获评“2023年国家级4星用户满意产品”。"
      },
      {
        "text": "《雪莲养护贴》行业标准外文版成为工信部唯一公示的行标外文版。"
      }
    ]
  },
  {
    "year": 2022,
    "wrapper": [
      {
        "text": "2月1日，由金天国际发起并起草、中国轻工业联合会提出并归口、由国家工信部发布的《雪莲养护贴》（QB/T 5630-2021）行业标准正式实施。"
      },
      {
        "text": "金天国际天山雪莲自然培育技术迎突破，助力生殖养护产业化迎花开之年。"
      },
      {
        "text": "促进全球健康发展，金天国际再度出席金砖峰会。"
      },
      {
        "text": "祖明军董事长连任“山东省公安民警优抚基金会第四届理事会副理事长”。"
      },
      {
        "text": "祖明军董事长当选为“中国健康促进基金会第四届理事会副理事长”。"
      },
      {
        "text": "集团旗下烟台金蕊女性用品有限公司通过“国家高新技术企业”认定。"
      }
    ]
  },
  {
    "year": 2021,
    "wrapper": [
      {
        "text": "出席中国-中东欧国家联合商会第六次会议。"
      },
      {
        "text": "金天云健康科研大厦及骨科医院喜封金顶。"
      },
      {
        "text": "再次荣获华夏大健康金手杖奖之2021年度国民信赖十大健康品牌奖。"
      },
      {
        "text": "历经归口、求证、受理、审批4大阶段15年不懈努力，由金天国际主导发起的《雪莲养护贴》行业标准经中华人民共和国工业和信息化部颁布实施，以行标的法定形式明确雪莲养护贴是用于人体生殖部位养护的一次性用品（生殖养护品），生殖养护行业正式诞生，第三种生活品质方式就此开启。"
      }
    ]
  },
  {
    "year": 2020,
    "wrapper": [
      {
        "text": "适应时代发展需要，“5G云商”生养平台启动。"
      },
      {
        "text": "雪莲养护贴行标通过公示，标志生态保养行业形成。"
      },
      // {
      //   "text": "践行“大循环”战略，研发出新消费企业生态联盟——金天合纵，祖明军董事长当选为国家智库暨中国国经中心理事。"
      // },
      {
        "text": "金天国际党委成立。"
      },
      {
        "text": "雪莲养护贴成“中医外治”样本。"
      },
      {
        "text": "发起并参与制定的山东省儿童口罩团队标准经审定后发布实施。"
      },
      {
        "text": "投身抗疫，累计捐赠款物价值超280万元。"
      },
      {
        "text": "集团旗下高登黛斯公司获得个护及家庭消费品全球标准BRC“A”级认证。"
      },
      {
        "text": "由金天国际承办的第四届全国中医药传承创新发展论坛在江苏宿迁召开。"
      },
      {
        "text": "金天御源医养健康项目于烟台福山区开工建设。"
      },
      {
        "text": "荣膺2020年度企业社会责任先锋奖。"
      },
      {
        "text": "出席2020-2021中国经济年会。"
      }
    ]
  },
  {
    "year": 2019,
    "wrapper": [
      // {
      //   "text": "雪莲养护、云健康、名食养、飞行营地暨“一核三星”产业布局全面形成。"
      // },
      {
        "text": "金天国际连锁经营机构遍布全球70多个国家和地区，产品受益者超1亿人。"
      },
      {
        "text": "受邀出席第二届“一带一路”国际合作高峰论坛、第七届中日韩工商峰会。"
      },
      {
        "text": "成为第二届世界领航飞行锦标赛国家队指定总冠名。"
      },
      {
        "text": "联合中科院发起成立“中国高品质中草药资源开发联合创新研究院”。"
      },
      {
        "text": "金天国际云健康产业总部落户南京江北新区。"
      },
      {
        "text": "金天国际阿连酋.迪拜中心店开业。"
      },
      {
        "text": "荣获“全国市场质量信用A等用户满意产品”、连续两年荣获“经济发展明星企业”称号。"
      }
    ]
  },
  {
    "year": 2018,
    "wrapper": [
      {
        "text": "设立“生命质量管理发展专项基金”。"
      },
      {
        "text": "荣膺上合组织工商论坛合作伙伴、中国国际商会战略合作黄金伙伴。"
      },
      {
        "text": "受邀出席博鳌亚洲论坛、夏季达沃斯新领军者年会、中非合作论坛北京峰会、第四届东方经济论坛，并参加首届进博会，与欧洲飞机制造商达成500架飞机采购订单。"
      },
      {
        "text": "雪莲养护行业标准获国家工信部批准立项。"
      },
      {
        "text": "2个月连续开启两轮专场定制海外邮轮之旅，超5000人参加。"
      },
      {
        "text": "荣获省级“制造业单项冠军”、“专精特新企业”、“质量管理优秀企业”、“用户满意产品”、“品牌成果创新一等奖”，荣登烟台市上半年百强经济发展贡献榜（34名）。"
      }
    ]
  },
  {
    "year": 2017,
    "wrapper": [
      {
        "text": "金天国际云健康、飞行营地产业相继全面启动。"
      },
      {
        "text": "“金天国际号”高铁品牌专列全速起航。"
      },
      {
        "text": "金天国际护基金成立。"
      },
      {
        "text": "受邀出席博鳌亚洲论坛、金砖五国峰会、中欧工商峰会、全球互联网经济大会及中越经贸论坛。"
      },
      {
        "text": "万人齐聚山东济南，共襄“活力金天 助力中国暨金天国际2017年撸起袖子加油干”荣耀盛典。"
      },
      {
        "text": "金天国际护基金向夏津县慈善总会捐赠100万元设立公益助学基金。"
      },
      {
        "text": "荣膺“年度经济发展明星企业”、“年度优秀企业”、“十大维权打假先进单位”称号。"
      }
    ]
  },
  {
    "year": 2016,
    "wrapper": [
      {
        "text": "获得国家“十三五”规划开篇之年首张直销经营许可证。"
      },
      {
        "text": "金天国际江苏宿迁全智能化产业园落成。"
      },
      {
        "text": "受邀出席2016年APEC工商领导人峰会。"
      },
      {
        "text": "金天国际烟台产业基地全面落成。"
      },
      {
        "text": "向山东省公安民警优抚基金会追加捐赠1000万元善款。"
      },
      {
        "text": "金天国际25周年梦想盛典暨公益筑梦远航在江苏宿迁召开。"
      }
    ]
  },
  {
    "year": 2015,
    "wrapper": [
      {
        "text": "被增选为“中国反侵权假冒创新战略联盟副理事长单位”。"
      },
      {
        "text": "捐款800万元建立“山东公安民警职业健康促进基金”。"
      }
    ]
  },
  {
    "year": 2013,
    "wrapper": [
      {
        "text": "雪莲养护产业获得国家认可及产业化认证，山东金天雪莲生态保养公司完成注册。"
      }
    ]
  },
  {
    "year": 2012,
    "wrapper": [
      {
        "text": "获得商务部核准的特许经营许可权。"
      },
      {
        "text": "携手山东省妇联共同举办 “春苗工程”战略合作签约仪式。"
      }
    ]
  },
  {
    "year": 2011,
    "wrapper": [
      {
        "text": "开创“三维动态式特许专营模式”，全面推动生态保养产业化。"
      },
      {
        "text": "荣获“中国蓝色经济区百强工程企业”称号。"
      }
    ]
  },
  {
    "year": 2010,
    "wrapper": [
      {
        "text": "雪莲产品获得 “2010亚太超级健康品牌”。"
      },
      {
        "text": "雪莲生态保养连锁专营创业计划被正式列为YBC重点创业项目。"
      }
    ]
  },
  {
    "year": 2009,
    "wrapper": [
      {
        "text": "集团旗下金蕊公司被评定为“女性创业示范基地”。"
      }
    ]
  },
  {
    "year": 2008,
    "wrapper": [
      {
        "text": "在全国美容养生市场推出“I+O”养生模式，获得热捧。"
      }
    ]
  },
  {
    "year": 2007,
    "wrapper": [
      {
        "text": "在新加坡组建金天国际集团，成立生态保养国际研发中心，开启生态保养全球化之路。"
      },
      {
        "text": "研发出生态保养之雪莲生态保养贴系列。"
      }
    ]
  },
  {
    "year": 2006,
    "wrapper": [
      {
        "text": "金天国际在健康护理的基础上研发出第四代雪莲贴垫-雪莲生态保养系列产品。基于市场层面强烈的生殖养护需求、生殖养护技术及产品的醇熟，企业于同年带头起草并发起制定雪莲养护贴行业标准，推动生殖养护产业化、规范化发展。"
      }
    ]
  },
  {
    "year": 2005,
    "wrapper": [
      {
        "text": "烟台金蕊女性用品有限公司荣获“AAA信用企业”、“市级守合同重信用企业”。"
      }
    ]
  },
  {
    "year": 2004,
    "wrapper": [
      {
        "text": "雪莲妇贴正式上市，配方、包装、生产工艺全方位焕新升级。"
      }
    ]
  },
  {
    "year": 2003,
    "wrapper": [
      {
        "text": "祖明军先生受邀到东南亚主讲“用生物气波治疗高血压”，扬中医养生之魂。"
      }
    ]
  },
  {
    "year": 2000,
    "wrapper": [
      {
        "text": "首创生物气波技术，在美国获得疾病治疗方法发明专利。"
      },
      {
        "text": "研发第三代护理型雪莲贴垫-雪莲妇贴，被政府认定为首个卫生护理型产品，备案号:鲁卫消备字(2002)第0009号。"
      },
      {
        "text": "雪莲药垫出口加拿大，深受加拿大人民热捧，集团旗下金蕊公司成为加中贸易理事会成员。"
      }
    ]
  },
  {
    "year": 1998,
    "wrapper": [
      {
        "text": "雪莲药垫作为新型妇科产品，在全国市场占用率排名前三。"
      },
      {
        "text": "美国斯坦福大学妇产学科主任、医学博士保罗.A.韩仕理率团参观集团科研中心，并积极在美国推广、经营雪莲产品，雪莲产品造福美国人民。"
      }
    ]
  },
  {
    "year": 1997,
    "wrapper": [
      {
        "text": "营业额实现两亿两千万，保持稳步发展，企业提前进入巩固期。"
      }
    ]
  },
  {
    "year": 1996,
    "wrapper": [
      {
        "text": "复方雪莲药垫被国家批为药健字号，批准文号:新卫药健字(1996)第0007号。"
      },
      {
        "text": "投入8000万元在烟台按GMP要求建立高标准的现代化科研生产中心，并同德国联合研制生态保养的专利设备及其生产线。"
      },
      {
        "text": "在新加坡组建金天国际集团，成立雪莲养护国际研发中心，开启雪莲养护全球化之路。"
      },
      {
        "text": "研发出雪莲养护之雪莲生态保养贴系列。"
      }
    ]
  },
  {
    "year": 1995,
    "wrapper": [
      {
        "text": "雪莲药垫被世界妇女大会指定为生殖健康产品，并首次出口欧洲。"
      },
      {
        "text": "雪莲产品荣获“中国国际技术产品展览会”金奖。"
      }
    ]
  },
  {
    "year": 1994,
    "wrapper": [
      {
        "text": "依托联合国“生殖健康是人类生命核心”的科学论断，在第一代治疗型雪莲药垫的基础上增加预防保健功能，研发出第二代“复方雪莲药垫”。"
      },
      {
        "text": "作为中医药创新典范，雪莲药垫被特批为创新药，获新药准字L（1994）001号。"
      },
      {
        "text": "雪莲药垫获得“全国发明与专利博览会”金杯奖、“上海中外产品博览会”优秀产品奖。"
      },
      {
        "text": "雪莲药垫、雪莲药膏基于创新被国家九部委认定为“国家级新产品”。"
      }
    ]
  },
  {
    "year": 1993,
    "wrapper": [
      {
        "text": "制定了企业30年发展规划。"
      },
      {
        "text": "组建新疆金蕊药业，开创贴垫类产品先河，成功研发出全球首个贴垫剂型妇科产品一妇得乐雪莲药垫，凭借独创的贴垫剂型成为中医药创新典范，产品获批新卫药准字(94)L-02号。"
      },
      {
        "text": "金蕊药业被国家科技部、财政部、国家税务总局评定为“高新技术企业”。"
      },
      {
        "text": "雪莲药垫在美国洛杉矶第四届世界医学大会上被评为“世界针灸和自然医学”金奖。"
      },
    ]
  },
  {
    "year": 1991,
    "wrapper": [
      {
        "text": "在政府的支持下，由新疆医科大学、农业大学、新疆金蕊等多家科研机构组建新疆雪莲生物研究所，专业研究雪莲资源的保护与应用，雪莲花的崭新纪元由此开启。。"
      }
    ]
  },
]