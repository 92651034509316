export const qiyeData = [
  { title: '2020烟台市最具社会责任企业', img: require("../assets/101.jpg") },
  { title: '2020山东省市场质量信用A等用户满意企业', img: require("../assets/102.jpg") },
  { title: '2018-2020中国国际商会战略合作黄金伙伴', img: require("../assets/103.jpg") },
  { title: '2019高新技术企业证书', img: require("../assets/104.jpg") },
  { title: '2019烟台市优秀民营企业', img: require("../assets/105.jpg") },
  { title: '中国国际商会 理事单位', img: require("../assets/106.jpg") },
  { title: '2018安全生产标准化三级企业-金蕊', img: require("../assets/107.jpg") },
  { title: '2018明星企业牌匾', img: require("../assets/108.jpg") },
  { title: '2018山东省制造业单项冠军企业', img: require("../assets/109.jpg") },
  { title: '2018山东省专精特新中小企业', img: require("../assets/110.jpg") },
  { title: '2018宿迁市优秀企业', img: require("../assets/111.jpg") },
  { title: '2018烟台市一企一技术研发中心', img: require("../assets/112.jpg") },
  { title: '2018烟台市中小企业创新转型优胜企业', img: require("../assets/113.jpg") },
  { title: '2016双打工作促进奖', img: require("../assets/114.jpg") },
  { title: '2017经济发展功勋企业', img: require("../assets/115.jpg") },
  { title: '2017年3A级诚信企业', img: require("../assets/116.jpg") },
  { title: '2017年度经济发展明星企业', img: require("../assets/117.jpg") },
  { title: '2017十大维权打假先进单位', img: require("../assets/118.jpg") },
  { title: '2017烟台市科技型中小企业', img: require("../assets/119.jpg") },
  { title: '2017优秀企业', img: require("../assets/120.jpg") },
  { title: '2017中国质量协会会员单位', img: require("../assets/121.jpg") },
  { title: '2005高新技术企业证书', img: require("../assets/122.jpg") },
  { title: '2005守合同重信用企业', img: require("../assets/123.jpg") },
  { title: '2011新加坡品牌', img: require("../assets/124.jpg") },
  { title: '2015中国反侵权假冒-副理事长单位', img: require("../assets/125.jpg") },
  { title: '2020年度企业社会责任先锋奖', img: require("../assets/2020年度企业社会责任先锋奖.jpg") },
  { title: '2021年度国民信赖十大健康品牌', img: require("../assets/2021年度国民信赖十大健康品牌.jpg") },
  { title: '3A级市场质量信用企业', img: require("../assets/217.jpg") },
  { title: '2023年省级用户满意五星企业', img: require("../assets/218.jpg") },
]

export const chanpinData = [
  { title: '国家商务部进出口资质', img: require("../assets/201.jpg") },
  { title: '第九届全国发明展览会银奖', img: require("../assets/202.jpg") },
  { title: '2020山东企业品牌创新成果', img: require("../assets/203.jpg") },
  { title: '2020山东企业品牌创新成果', img: require("../assets/204.jpg") },
  { title: '2019山东省企业技术创新奖', img: require("../assets/205.jpg") },
  { title: '2019年全国市场质量信用A等（用户满意）企业', img: require("../assets/206.jpg") },
  { title: '2019年第三十三届山东省企业管理现代化创新成果', img: require("../assets/207.jpg") },
  { title: '2018山东省企业品牌创新成果一等奖', img: require("../assets/208.jpg") },
  { title: '2018年山东省用户满意产品', img: require("../assets/209.jpg") },
  { title: '2016金口碑-亚太区最具品牌价值明显产品', img: require("../assets/210.jpg") },
  { title: '2010亚太超级健康品牌', img: require("../assets/211.jpg") },
  { title: '95年中国国际技术产品展览会金奖', img: require("../assets/212.jpg") },
  { title: '94年中外生活用品食品博览会优秀产品奖', img: require("../assets/213.jpg") },
  { title: '94年全国发明与专利博览会金杯奖', img: require("../assets/214.jpg") },
  { title: '94年国家级新产品', img: require("../assets/215.jpg") },
  { title: '93年世界针灸和自然医学金奖', img: require("../assets/216.jpg") },
  { title: '2023年国家级四星级用户满意产品', img: require("../assets/2023年国家级四星级用户满意产品.jpg") },
]